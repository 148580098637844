// Barra de progresso para certificações
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

// Modelo da barra de progresso para as certificações Labora
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  width: 600,
  borderRadius: 15,
  [theme.breakpoints.down("sm")]: {
    height: 10,
    width: 210,
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: 13,
    width: 390,
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: 15,
    width: 450,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: theme.palette.mode === "light" ? "#04b304" : "#f7a600",
  },
}));

// Modelo das linhas de descrição das certificações
const Paragrafo = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  color: "text.secondary",
  fontFamily: "Raleway",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.45rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "0.75rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1rem",
  },
}));

export default function BarraProgresso({ barra }) {
  let porcentagem = Math.floor(barra.porcento_curso * 100);
  let inicio = barra.inicio_curso;
  let fim;
  if (barra.fim_curso === "Data inválida") {
    fim = "não concluída !";
  } else {
    fim = barra.fim_curso;
  }

  // Cria os links das certificações Labora não iniciadas ou em andamento
  let link_curso = false;
  let curso;
  if (porcentagem !== 100) {
    link_curso = true;
    if (barra.id_curso === 772853) {
      curso =
        "https://certificado-labora.thinkific.com/courses/bem-vindo-a-sua-2a-carreira";
    } else if (barra.id_curso === 848187) {
      curso =
        "https://certificado-labora.thinkific.com/courses/experiencia-do-cliente";
    } else if (barra.id_curso === 1001238) {
      curso =
        "https://certificado-labora.thinkific.com/courses/produtos-digitais";
    } else if (barra.id_curso === 2215145) {
      curso =
        "https://certificado-labora.thinkific.com/courses/especialista-inside-sales";
    } else if (barra.id_curso === 1620534) {
      curso =
        "https://certificado-labora.thinkific.com/courses/diversidade-geracional";
    } else if (barra.id_curso === 2416561) {
      curso =
        "https://certificado-labora.thinkific.com/courses/inside-sales-bdr-e-sdr";
    }
  }

  // Verifica se o curso foi iniciado ou não
  let semCurso = false;
  if (barra.inicio_curso === "Certificação não iniciada !") {
    semCurso = true;
  }

  return (
    // Mostra o início e fim do curso
    <Box sx={{ display: "flexBox", alignItems: "center" }}>
      <Box sx={{ flexGrow: 1 }}>
        {!semCurso && (
          <Paragrafo>
            <strong>Início: </strong>
            {inicio}
            {"  -  "}
            <strong> Término: </strong>
            {fim}
          </Paragrafo>
        )}
        {semCurso && (
          <Paragrafo>
            <strong>Início: </strong>
            {inicio}
          </Paragrafo>
        )}
      </Box>
      {/* Mostra a barra de progresso */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <BorderLinearProgress variant="determinate" value={porcentagem} />
        </Box>
        <Box sx={{ minWidth: 1 }}>
          <Paragrafo marginLeft="0.5rem">{porcentagem}%</Paragrafo>
        </Box>
      </Box>
      {/* Mostra o link para continuar o curso */}
      {link_curso && !semCurso && (
        <Paragrafo>
          <a href={curso} target="_blank" rel="noreferrer">
            Clique aqui para continuar a certificação agora !
          </a>
        </Paragrafo>
      )}
      {/* Mostra o link para iniciar o curso */}
      {link_curso && semCurso && (
        <Paragrafo>
          <a href={curso} target="_blank" rel="noreferrer">
            Clique aqui para iniciar esta certificação !
          </a>
        </Paragrafo>
      )}
    </Box>
  );
}
