import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { urlResetPassword } from "../utils/Utils.js";
import BannerTermoUso from "../components/BannerTermoUso/BannerTermoUso.jsx";
import { Box, Button, TextField } from "@mui/material";
import ModalMsg from "../components/ModalMsg/ModalMsg.jsx";

export default function PasswordReset() {
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [reload, setReload] = useState(false);
  const recaptchaRef = useRef();
  let navigate = useNavigate();
  const routeToLogin = () => navigate("/");
  const testeLoad = () => {
    // console.log("reCaptcha carregada");
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    setReload(!reload);
    const captcha = await recaptchaRef.current.executeAsync();
    // console.log("E-mail enviado para reset de senha.", email);
    // console.log("captcha value", captcha);
    // console.log("email enviado no formulário", email);
    if (email) {
      // console.log(`=>${urlResetPassword}/${email}`);
      await axios
        .post(urlResetPassword, { email, captcha })
        .then((response) => {
          if (response.status === 200) {
            setErrMsg('Atenção, caso o e-mail esteja cadastrado uma nova senha será enviada.');            
            const data = { resetPass: false };

            //console.log(`Atenção\nCaso o email esteja cadastrado uma nova senha será enviada.`);
          } else {
            // alert(`Erro: Não foi possível enviar a requisição.`);
            setErrMsg('E-mail não cadastrado, favor verificar o e-mail digitado.');
          }
        })
        .catch((error) => {
          // alert(`Erro: Não foi possível enviar a requisição.`);
          setErrMsg('E-mail não cadastrado, favor verificar o e-mail digitado.');
          // console.log("error do catch",error);
          recaptchaRef.current.reset();
        });
    } else {
      setErrMsg('E-mail não foi preenchido.');
    };
    return ;  
}

  function cancelar() {
    const data = { resetPass: false };
    routeToLogin();
  }

  return (
    <>
      <div>
        <BannerTermoUso></BannerTermoUso>
      </div>

      <div className="container centro">
        <h1>Redefinição de senha</h1>
        <h4>Entre com seu e-mail que lhe</h4>
        <h4>enviaremos uma nova senha</h4>

        <form className="w75" onSubmit={handleFormSubmit}>
          <div className="pb40">
            <Box
              className="box"
              sx={{ '& .MuiTextField-root': { m: 1, width: '95%' }, }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="E-mail do usuário"
                type="email"
                id="email"
                name="email"
                variant="outlined"
                size="medium"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdzTBYgAAAAAN-toGlGYaNxFWTyxYwNVuXlXvOd"
            asyncScriptOnLoad={testeLoad}
            // onChange={onChange}
            size="invisible"
            hl="pt-BR"
            onExpired={() => {recaptchaRef.current.reset()}}
            badge= "bottomleft"
          />
          <div className="pt10 botoes">
            <Button
              className="btn-banner"
              variant="contained"
              color="secondary"
              value="Cancelar"
              onClick={cancelar}
            >
              Cancelar
            </Button>
            <Button
              className="btn-banner"
              variant="contained"
              type="submit"
              color="secondary"
              value="Enviar"
            >
              Enviar
            </Button>
          </div>
        </form>
        {errMsg === 'Atenção, caso o e-mail esteja cadastrado uma nova senha será enviada.' && (
              <ModalMsg msg={errMsg} modalType={"success"}/>
            )}
        {errMsg === 'E-mail não foi preenchido.' && (
              <ModalMsg msg={errMsg} modalType={"success"}/>
            )}
        {errMsg === 'E-mail não cadastrado, favor verificar o e-mail digitado.' && (
              <ModalMsg msg={errMsg} modalType={"success"}/>
            )}
            
      </div>
    </>
  );
}