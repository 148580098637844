import React from "react";
import { IconContext } from "react-icons";
import { BsSpotify } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import "./SocialMedias.css";

let ano = new Date().getFullYear();

export default function SocialMedias() {
  return (
    <div className="social-medias">
      <p>@ {ano} Labora Tech</p>
      <div id="social-icons">
        <IconContext.Provider value={{ className: "top-react-icons" }}>
          <a href="https://www.facebook.com/comunidadeLabora" target="_blank" rel="noreferrer">
            <BsFacebook />
          </a>
          <a href="https://www.instagram.com/labora.tech/" target="_blank" rel="noreferrer">
            <BsInstagram />
          </a>
          <a href="https://www.linkedin.com/company/labora-tech/" target="_blank" rel="noreferrer">
            <BsLinkedin />
          </a>
          <a href="https://www.youtube.com/channel/UC7TOZ8bmmrOh5-h1Zc2EyPg" target="_blank" rel="noreferrer">
            <BsYoutube />
          </a>
          <a href="https://open.spotify.com/show/3E1mBtiM7yGWf3eFptaI1v?si=z-db5HY-QBKHeOoD7u3J9g" target="_blank" rel="noreferrer">
            <BsSpotify />
          </a>
        </IconContext.Provider>
      </div>
    </div>
  );
}
