import React, { useEffect, useState } from "react";
import { readUser } from "../../services/api";
import laboralogo from "../../assets/laboralogo_banner.png";
import MenuIcon from "../MenuIcon/MenuIcon.jsx";
import BannerTitle from "../BannerTitle/BannerTitle.jsx";
import "./Banner.css";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
// import Button from '@mui/material/Button';

export default function Banner({ title, description }) {

	const token = localStorage.getItem("token");

	let [bannerDataResponse, setBannerDataResponse] = useState("");
	let [loaded, setLoaded] = useState(false);

	useEffect(() => {
		async function fetchBanner() {
			const bannerResponse = await readUser(token);
			setBannerDataResponse(bannerResponse);
			setLoaded(true);
		}
		fetchBanner();
	}, [token]);

	if(!loaded) return <div>Carregando Banner</div>;
	else{

		return (
			<div className="banner">
				<img className="img-logo-banner" src={laboralogo} alt="logo labora tech" />
				<div className="banner-title">
					< BannerTitle title={title} />
				</div>
				<span>Oi, {bannerDataResponse.data.first_name}</span>
				<MenuIcon /*photo={bannerDataResponse.data.cadastro.photo}*//>
			</div>
		);
	}
}
