import React from "react";
import "./BannerTermoUso.css";
import laboralogo from "../../assets/laboralogo_banner.png";

export default function BannerTermoUso() {
    return (
        <div className="banner-termo">
            <img className="img-logo" src={laboralogo} alt="logo labora tech" />
        </div>
    );
}
