import React, { useEffect, useState } from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import SocialMedias from "../components/SocialMedias/SocialMedias.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import Adicionar from "../components/Calendario/Adicionar.jsx";
import Box from '@mui/material/Box';
import "moment/locale/pt-br";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../components/Calendario/Calendario.css";
import EventModal from "../components/Calendario/EventModal";
import EventoPadrao from "../components/Calendario/EventoPadrão";

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

export default function Disponibilidade() {
    // const token = localStorage.getItem("token");
    // const [loaded, setLoaded] = useState(false);
    // const moment = require("moment");
    // const [certificado, setCertificado] = useState("");
    // const [existeCertificado, setExisteCertificado] = useState(true);

    // useEffect(() => {
    //     async function fetchCertificados() {
    //         const certificadosResponse = await readCertificados(token);
    //         setLoaded(true);
    //         console.log("CertificadosResponse: ", certificadosResponse);
    //         if (certificadosResponse.data.rows.length !== 0) {
    //             console.log("CertificadosResponse: ", certificadosResponse);
    //             const select = certificadosResponse.data.rows.map((selecao, index) => {
    //                 return {
    //                     id: index,
    //                     instituicao: selecao.instituicao,
    //                     curso: selecao.curso,
    //                     tipo: selecao.tipo,
    //                     inicio: moment(selecao.data_inicio).locale("pt-br").format("LL"),
    //                     fim: moment(selecao.data_conclusao).locale("pt-br").format("LL"),
    //                     tempo: new Date(selecao.data_inicio).getTime()
    //                 }
    //             });
    //             let resultado = [...select].sort((a, b) => a.tempo > b.tempo ? 1 : -1);
    //             setCertificado(resultado);
    //         } else { setExisteCertificado(false); }
    //     }
    //     fetchCertificados();
    // }, [token, moment]);

    // if (!loaded) {
    //     return <div>Carregando Certificados...</div>;
    // } else {
    //     return (
    //         <>
    //             <div className="container-header">
    //                 <header className="app-header">
    //                     <Banner title="Certificados" description="Certificados Labora Tech." />
    //                 </header>
    //             </div>
    //             <div className="container-perfil">
    //                 <div className="container-menu">
    //                     <MenuPerfil></MenuPerfil>
    //                 </div>
    //                 <div className="box-jornada">
    //                     <div className="procsel-header">
    //                         {existeCertificado && (
    //                             <h1>Certificados cadastrados:</h1>)}
    //                         {!existeCertificado && (
    //                             <h1>Não possui certificados cadastrados !</h1>
    //                         )}
    //                     </div>
    //                     {existeCertificado && (
    //                         certificado.map((certifica, index) => (
    //                             <div className="procsel-nome" key={index}>
    //                                 <Box sx={{ display: 'flexBox' }}>
    //                                     <Box sx={{ flexGrow: 1, mt: -0.5, pl: 1, pr: 2 }}>
    //                                         <p className="certificado-p"><strong>INSTITUIÇÃO: </strong>{certifica.instituicao}</p>
    //                                         <p className="certificado-p"><strong>CURSO: </strong>{certifica.curso} - <strong>TIPO: </strong>{certifica.tipo}</p>
    //                                         <p className="certificado-p"><strong>INÍCIO: </strong>{certifica.inicio} - <strong>CONCLUSÃO: </strong>{certifica.fim}</p>
    //                                     </Box>
    //                                 </Box>
    //                             </div>
    //                         ))
    //                     )}
    //                 </div>
    //             </div >
    //             <Footer></Footer>
    //             <SocialMedias></SocialMedias>
    //         </>
    //     );
    // }
    const [eventos, setEventos] = useState(EventoPadrao);
    const [eventoSelecionado, setEventoSelecionado] = useState(null);

    const eventStyle = (event) => ({
        style: {
            backgroundColor: event.color,
        },
    });

    const moverEventos = (data) => {
        const { start, end } = data;
        const updatedEvents = eventos.map((event) => {
            if (event.id === data.event.id) {
                return {
                    ...event,
                    start: new Date(start),
                    end: new Date(end)
                };
            }
            return event;
        });
        setEventos(updatedEvents);
    };

    const handleEventClick = (evento) => {
        setEventoSelecionado(evento);
    };

    const handleEventClose = () => {
        setEventoSelecionado(null);
    };

    const handleAdicionar = (novoEvento) => {
        // lógica do banco
        setEventos([...eventos, { ...novoEvento, id: eventos.length + 1 }]);
    };

    const handleEventDelete = (eventId) => {
        // lógica do banco
        const updatedEvents = eventos.filter((event) => event.id !== eventId);
        setEventos(updatedEvents);
        setEventoSelecionado(null);
    };

    const handleEventUpdate = (updatedEvent) => {
        // lógica do banco
        const updatedEvents = eventos.map((event) => {
            if (event.id === updatedEvent.id) {
                return updatedEvent;
            };
            return event;
        });
        setEventos(updatedEvents);
        setEventoSelecionado(null);
    };

    return (
        <>
            <div className="container-header">
                <header className="app-header">
                    <Banner title="Disponibilidade" description="Disponibilidade Labora Tech." />
                </header>
            </div>
            <div className="container-perfil">
                <div className="container-menu">
                    <MenuPerfil></MenuPerfil>
                </div>
                <div className="tela">
                    <div className="toolbar">
                        <Adicionar onAdicionar={handleAdicionar} />
                    </div>
                    <div className="calendario">
                        <DragAndDropCalendar
                            defautDate={moment().toDate()}
                            defaultView="month"
                            events={eventos}
                            localizer={localizer}
                            resizable
                            onEventDrop={moverEventos}
                            onEventResize={moverEventos}
                            onSelectEvent={handleEventClick}
                            eventPropGetter={eventStyle}
                            className="calendar"
                        />
                        {eventoSelecionado && (
                            <EventModal
                                evento={eventoSelecionado}
                                onClose={handleEventClose}
                                onDelete={handleEventDelete}
                                onUpdate={handleEventUpdate}
                            />
                        )}

                    </div>
                </div>
            </div>
            <Footer></Footer>
            <SocialMedias></SocialMedias>
        </>

    );

}
