import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import { Button, MenuItem, Checkbox } from "@mui/material";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import SocialMedias from "../components/SocialMedias/SocialMedias.jsx";
import "./PagesStyles.css";
import { updateUser, readUser } from "../services/api";
import ModalMsg from "../components/ModalMsg/ModalMsg.jsx";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import PolPrivacidade from "../components/PolPrivacidade/PolPrivacidade";
import {
  meses,
  estadoss,
  generos,
  racas,
  lgbts,
  transs,
  deficientes,
} from "../components/VariaveisOpcoes/VariaveisOpcoes.jsx";

export default function Perfil() {
  const token = localStorage.getItem("token");
  const [errMsg, setErrMsg] = useState("");
  const [userDataResponse, setUserDataResponse] = useState();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const routeToHome = () => navigate("/Home");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("handle event");
  };

  const setFormData = (formData, data) => {
    const keys = Object.keys(data);
    // console.log("keys:", keys);
    for (let i in keys) {
      if (typeof data[keys[i]] === "object") {
        for (let j in data[keys[i]]) {
          // console.log(`=> ${keys[i]}[${j}]: ${data[keys[i]][j]}`);
          if (typeof data[keys[i]][j] === "object") {
            formData.append(
              `${keys[i]}[${j}]`,
              JSON.stringify(data[keys[i]][j])
            );
          } else {
            formData.append(`${keys[i]}[${j}]`, data[keys[i]][j]);
          }
        }
      } else {
        //console.log(`${i}
        //console.log(`${i} || ${keys[i]}: ${data[keys[i]]}`);
        formData.append(`${keys[i]}`, data[keys[i]]);
      }
    }
  };

  // const [image, setImage] = useState('');
  // const [endImg, setEndImg] = useState("");

  const onSubmit = (data) => {
    let formData = new FormData();
    //formData.append('filetoupload', image);
    // const keys = Object.keys(data);
    // for (let i in keys) {
    //   formData.append(`${keys[i]}`, data[keys[i]]);
    // }
    // console.log("=> Conteudo do formData!");

    setFormData(formData, data);

    for (let i of formData.entries()) {
      console.log("FormData: ", i[0] + ", " + i[1]);
    }

    async function updateDataUser() {
      await updateUser(token, formData).then((response) => {
        console.log("response do perfil", response);
        if (response.status >= 200 && response.status < 400) {
          setErrMsg("Atualização realizada com sucesso!");
        }
      });
    }
    updateDataUser();
  };

  useEffect(() => {
    async function fetchData() {
      const dataResponse = await readUser(token);
      setUserDataResponse(dataResponse);
      setLoaded(true);
      console.log("DataResponse no perfil:", dataResponse);
      // setEndImg(dataResponse.data.cadastro.photo);
    }
    fetchData();
  }, [token]);

  if (!loaded) return <div>Carregando...</div>;
  else {
    return (
      <>
        <div className="container-header">
          <header className="App-header">
            <Banner title="Seu Perfil" description="Perfil Labora Tech." />
          </header>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-perfil">
            <div className="container-menu">
              <MenuPerfil></MenuPerfil>
            </div>
            <div className="container-box pt10">
              {/* <Box sx={{ mb: 2, border: "1px solid #d1d2d2", borderRadius: 1 }} className="header-talento">
								<Box className="label">
									<label className="label-talento" htmlFor="Foto">Selecione sua foto de até 5MB e em formato quadrado:</label>
								</Box>
								<Box className="box-talento">
									<Box className="box-imagem">
										{image ? <img src={URL.createObjectURL(image)} alt="Imagem" className="img-talento" /> :
											<img src={endImg} alt="Imagem 1" className="img-talento" />}
									</Box>
									<Box className="box-imagem">
										<input type="file" accept="image/*" name="photo" id="photo" onChange={e => setImage(e.target.files[0])} />
									</Box>
								</Box>
							</Box> */}
              <TextField
                label="E-mail"
                className="textfield-2 fl"
                variant="outlined"
                size="small"
                value={userDataResponse.data.email}
                readOnly={true}
                required
                {...register("email")}
              />
              <TextField
                label="CPF"
                className="textfield-2 fr"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro3.cpf}
                placeholder="999.999.999-99"
                required
                readOnly={true}
                {...register("cadastro3.cpf", {
                  pattern: {
                    value: /^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/,
                    message: "Padrão correto: 999.999.999-99",
                  },

                })}
              />
              <ErrorMessage errors={errors} name="cadastro3.cpf" render={({ message }) => <p>{message}</p>} />
              <TextField
                label="Nome"
                className="textfield-3 fl"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.first_name}
                required
                {...register("first_name")}
              />
              <TextField
                label="Sobrenome"
                className="textfield-3"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.last_name}
                required
                {...register("last_name")}
              />
              <TextField
                label="Telefone"
                className="textfield-3 fr"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro1.phone}
                placeholder="(DD) 99999-9999"
                required
                {...register("cadastro1.phone", {
                  pattern: {
                    value: /\(\d{2}\)\s\d{4,5}-\d{4}$/,
                    message: "Padrão correto: (DD) 99999-9999",
                  },
                })}
              />
              <ErrorMessage errors={errors} name="cadastro1.phone" render={({ message }) => <p>{message}</p>} />
              <TextField
                label="Dia de nascimento"
                className="textfield-3 fl"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro2.birthday_day}
                required
                {...register("cadastro2.birthday_day")}
              />
              <TextField
                label="Mês de nascimento"
                className="textfield-3"
                select
                onChange={handleChange}
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro2.birthday_month}
                required
                {...register("cadastro2.birthday_month")}
              >
                {meses.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Ano de nascimento"
                className="textfield-3 fr"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro2.birthday_year}
                required
                {...register("cadastro2.birthday_year")}
              />
              <TextField
                label="Endereço"
                className="textfield-1"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro1.address}
                {...register("cadastro1.address")}
              />
              <TextField
                label="Cidade"
                className="textfield-3 fl"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro1.city}
                required
                {...register("cadastro1.city")}
              />
              <TextField
                label="Estado"
                className="textfield-3"
                select
                defaultValue={userDataResponse.data.cadastro1.state}
                onChange={handleChange}
                variant="outlined"
                size="small"
                required
                {...register("cadastro1.state")}
              >
                {estadoss.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="CEP"
                className="textfield-3 fr"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro1.cep}
                onChange={handleChange}
                placeholder="99999-999"
                required
                {...register("cadastro1.cep", {
                  pattern: {
                    value: /\d{5}-\d{3}$/,
                    message: "Padrão correto: 99999-999"
                  }
                })}
              />
              <ErrorMessage errors={errors} name="cadastro1.cep" render={({ message }) => <p>{message}</p>} />
              <TextField
                label="Etnia/raça autodeclarada"
                className="textfield-2a fl"
                select
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                defaultValue={userDataResponse.data.cadastro2.ethnic_group}
                {...register("cadastro2.ethnic_group")}
              >
                {racas.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Gênero autodeclarado"
                className="textfield-2a fr"
                select
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                defaultValue={userDataResponse.data.cadastro2.gender}
                {...register("cadastro2.gender")}
              >
                {generos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Você é uma pessoa LGBTQIA+?"
                className="textfield-2a fl"
                select
                defaultValue={
                  userDataResponse.data.cadastro2.sexual_orientation
                }
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                {...register("cadastro2.sexual_orientation")}
              >
                {lgbts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Você possui alguma deficiência?"
                className="textfield-2a fr"
                select
                defaultValue={userDataResponse.data.cadastro2.deficiency}
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                {...register("cadastro2.deficiency")}
              >
                {deficientes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Identifica-se como pessoa trans?"
                className="textfield-1"
                select
                defaultValue={userDataResponse.data.cadastro2.transsexual}
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                {...register("cadastro2.transsexual")}
              >
                {transs.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* <div className="pt10 pb20 botao-perfil"> */}
              <div className="centro pt10">
                <PolPrivacidade></PolPrivacidade>
                <div className="pt10 pb10">
                  <p className="termo-uso">
                    Leia e aceite a política de privacidade para atualizar o
                    cadastro
                  </p>
                  <Checkbox
                    className="checkbox"
                    size="small"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <strong className="strong">
                    Concordo com a política de privacidade Labora
                  </strong>
                </div>
                <div className="pt10 pb20 botao-perfil">
                  <Button
                    type="submit"
                    className="btn-banner"
                    variant="contained"
                    color="third"
                    disabled={!checked}
                  >
                    Salvar
                  </Button>
                  <Button
                    className="btn-banner"
                    variant="contained"
                    color="secondary"
                    onClick={routeToHome}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
            {/* {console.log("erro mensagem antes do modal", errMsg)} */}
            {errMsg === "Atualização realizada com sucesso!" && (
              <ModalMsg msg={errMsg} modalType={"editedToHome"} />
            )}
          </div>
        </form>
        <Footer></Footer>
        <SocialMedias></SocialMedias>
      </>
    );
  }
}
