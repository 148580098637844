// Fornece a foto para cada certificação
import React from "react";
import "./CertificadoFoto.css";
import segcarreira from "../../assets/images/cert1_2carreira.png";
import expcliente from "../../assets/images/cert2_expcliente.png";
import proddigitais from "../../assets/images/cert3_proddigitais.png";
import oracleone from "../../assets/images/cert4_oracleone.jpeg";
import descomplique from "../../assets/images/cert5_sebrae.jpg";
import insidesales from "../../assets/images/cert6_insidesales.png";
import labora from "../../assets/images/laboralogo2.png";
import diversidade from "../../assets/images/cert7_incldivger.png";
import bdr_sdr from "../../assets/images/cert8_Bdr_Sdr.jpg";

// Seleciona a foto de acordo com a certificação
export default function CertificadoFoto({ foto }) {
  let logoLabora;
  if (foto.id_curso === 772853) {
    logoLabora = segcarreira;
  } else if (foto.id_curso === 848187) {
    logoLabora = expcliente;
  } else if (foto.id_curso === 1001238) {
    logoLabora = proddigitais;
  } else if (foto.id_curso === 2215145) {
    logoLabora = insidesales;
  } else if (foto.id_curso === 1620534) {
    logoLabora = diversidade;
    // } else if (foto.id_curso === 1114642) {
  } else if (foto.id_curso === 2416561) {
    logoLabora = bdr_sdr;
  } else if (foto.id_curso === "Sebrae") {
    logoLabora = descomplique;
  } else if (foto.id_curso === "Oracle" || foto.id_curso === "Alura/Oracle") {
    logoLabora = oracleone;
  } else {
    logoLabora = labora;
  }

  return (
    <>
      <div className="jornada-logo">
        <img className="labora-logo" src={logoLabora} alt="logo Labora" />
      </div>
    </>
  );
}
