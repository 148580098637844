import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import BannerTermoUso from '../BannerTermoUso/BannerTermoUso';
import "./ModalOportunidades.css"
import "moment/locale/pt-br";

export default function ModalOportunidades({ data }) {
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    let descricaoModal = data.descricao_modal.split("/*/");

    let objeto1, objeto2, objeto3, objeto4, objeto5, objeto6 = "";
    let notNull1, notNull2, notNull3, notNull4, notNull5, notNull6 = false;
    if ((data.first_subject_value !== null) && (data.first_subject_value !== "")) {
        objeto1 = data.first_subject_value.split(";");
        notNull1 = true;
    };
    if ((data.second_subject_value !== null) && (data.second_subject_value !== "")) {
        objeto2 = data.second_subject_value.split(";");
        notNull2 = true;
    };
    if ((data.third_subject_value !== null) && (data.third_subject_value !== "")) {
        objeto3 = data.third_subject_value.split(";");
        notNull3 = true;
    };
    if ((data.fourth_subject_value !== null) && (data.fourth_subject_value !== "")) {
        objeto4 = data.fourth_subject_value.split(";");
        notNull4 = true;
    };
    if ((data.fifth_subject_value !== null) && (data.fifth_subject_value !== "")) {
        objeto5 = data.fifth_subject_value.split(";");
        notNull5 = true;
    };
    if ((data.sixth_subject_value !== null) && (data.sixth_subject_value !== "")) {
        objeto6 = data.sixth_subject_value.split(";");
        notNull6 = true;
    };

    let inscricaoAberta = false;
    let inscricaoFechada = true;

    if (data.status) {
        inscricaoAberta = true;
        inscricaoFechada = false;
    } else {
        inscricaoAberta = false;
        inscricaoFechada = true;
    }

    return (
        <>
            <div>
                <div className="botaoSenha">
                    <Button variant='contained' color='secondary' onClick={handleClickOpen('paper')}>
                        Saiba mais
                    </Button>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    maxWidth="sm"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <div className="close-icon">
                                <CloseIcon onClick={handleClose} />
                            </div>
                            <header><BannerTermoUso /></header>

                            <div className="container-oportunidades">
                                {inscricaoAberta &&
                                    <div>
                                        <p className="titulo-modal">{data.titulo_modal}</p>
                                        {descricaoModal.map((objeto) =>
                                            <p key={objeto} className="titulo-modal-descricao">{objeto}</p>)}
                                        <p className="subtitulo-modal">{data.first_subject}</p>
                                        {notNull1 && objeto1.map((objeto) =>
                                            <li className="objeto" key={objeto}>{objeto}</li>)}
                                        <p className="subtitulo-modal">{data.second_subject}</p>
                                        {notNull2 && objeto2.map((objeto) =>
                                            <li className="objeto" key={objeto}>{objeto}</li>)}
                                        <p className="subtitulo-modal">{data.third_subject}</p>
                                        {notNull3 && objeto3.map((objeto) =>
                                            <li className="objeto" key={objeto}>{objeto}</li>)}
                                        <p className="subtitulo-modal">{data.fourth_subject}</p>
                                        {notNull4 && objeto4.map((objeto) =>
                                            <li className="objeto" key={objeto}>{objeto}</li>)}
                                        <p className="subtitulo-modal">{data.fifth_subject}</p>
                                        {notNull5 && objeto5.map((objeto) =>
                                            <li className="objeto" key={objeto}>{objeto}</li>)}
                                        <p className="subtitulo-modal">{data.sixth_subject}</p>
                                        {notNull6 && objeto6.map((objeto) =>
                                            <li className="objeto" key={objeto}>{objeto}</li>)}
                                        <div className="pt10 botao centro">
                                            <a
                                                role="button"
                                                className="btn-modal"
                                                href={data.link_inscricao}
                                            >
                                                Inscreva-se
                                            </a>
                                        </div>
                                    </div>
                                }

                                {inscricaoFechada &&
                                    <div>
                                        <p className="subtitulo-modal-descricao">Esta oportunidade está encerrada.</p>
                                        <p className="subtitulo-modal-descricao"> Acompanhe a Labora e fique por dentro de todas as novas oportunidades
                                            desenvolvidas para a comunidade.</p>
                                        <p className="subtitulo-modal-descricao">Comece a sua certificação "Bem Vind@ à sua 2ª Carreira", ela é
                                            gratuita e é a porta de entrada para todas as oportunidades da
                                            Labora!</p>
                                        <div className="pt10 botao centro">
                                            <a
                                                role="button"
                                                className="btn-modal"
                                                href={"https://certificado-labora.thinkific.com/courses/bem-vindo-a-sua-2a-carreira"}
                                            >
                                                Comece agora
                                            </a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        </>
    );
}