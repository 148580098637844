import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import SocialMedias from "../components/SocialMedias/SocialMedias.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";

export default function Home() {
	return (
		<>
			<header>
				<Banner title="Portal de Talentos" description="Página de talentos Labora Tech." />
			</header>

			<div className="container-perfil">
				<div className="container-menu">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box">
					<h1 class="referral" className="header-facebook">Notícias da Labora</h1>
					<link
						href="https://assets.juicer.io/embed.css"
						media="all"
						rel="stylesheet"
						type="text/css"
					/>
					<ul
						className="juicer-feed"
						data-feed-id="labora-6f25dc31-69f5-4328-ae2b-ebe4043eb44a"
						// data-pages="2"
						data-per="4"
						data-columns="4"
						data-truncate="150"
						data-gutter="50"
						data-origin="embed-code">
					</ul>
				</div>
			</div>
			<Footer />
			<SocialMedias />
		</>
	);
}
