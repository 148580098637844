import React, { useContext, useState } from 'react';
import { AuthContext } from "../../contexts/auth";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import "./MenuIcon.css";
import { useNavigate } from 'react-router-dom';

export default function MenuIcon({ photo }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log("event.currentTarget",event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
  };

  let navigate = useNavigate();
  const routeToHome = () => navigate('/home');
  const routeToPerfil = () => navigate('/perfil');
  const routeToOportunidades = () => navigate('/oportunidades');
  const routeToUpdatePassword = () => navigate("/updatePassword");
  const routeTocertificacao = () => window.open('https://certificado-labora.thinkific.com/', '_blank');
  const routeToJornadaLabora = () => navigate("/jornadaLabora");
  const routeToDisponibilidade = () => navigate("/disponibilidade");
  const routeToProcSeletivos = () => navigate("/procSeletivos");
  const routeToJornadasRealizadas = () => navigate("/jornadasRealizadas");
  // const routeToEmpresas = () => navigate("/empresas");
  // const routeToCertificados = () => navigate("/certificados");

  let icone = true;
  // let foto = false;
  // if (photo !== null && photo !== "") {
  //   icone = false;
  //   foto = true;
  // }

  return (
    <div>
      {icone &&
        <AccountCircleTwoToneIcon
          className="icon-user"
          id="basic-menu"
          // sx={{ fontSize: 50 }}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick} />}

      {/* {foto &&
        <img
          className="banner-foto"
          id="basic-menu"
          src={photo}
          alt="foto"
          onClick={handleClick} />} */}

      <Menu
        className="basic-menu"
        // id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'icon-user',
        }}
      >
        <nav>
          <MenuItem onClick={routeToHome}>Home</MenuItem>
          <MenuItem onClick={routeToPerfil}>Perfil</MenuItem>
          <MenuItem onClick={routeTocertificacao}>Certificações Labora</MenuItem>
          <MenuItem onClick={routeToOportunidades}>Oportunidades</MenuItem>
          <MenuItem onClick={routeToUpdatePassword}>Alterar Senha</MenuItem>
          <MenuItem onClick={routeToJornadaLabora}>Certificações</MenuItem>
          <MenuItem onClick={routeToDisponibilidade}>Disponibilidade</MenuItem>
          <MenuItem onClick={routeToProcSeletivos}>Processos Seletivos</MenuItem>
          <MenuItem onClick={routeToJornadasRealizadas}>Jornadas Realizadas</MenuItem>
          {/* <MenuItem onClick={routeToEmpresas}>Trabalho Flex</MenuItem> */}
          {/* <MenuItem onClick={routeToCertificados}>Certificados</MenuItem> */}
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </nav>
      </Menu>

    </div>
  );
}