import React from "react";
import Rotas from "./routes/index.js";

function App() {
  return (
      <Rotas />   
  );
}

export default App;
