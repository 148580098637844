//API v3:
//export const baseUrl = "https://userapi3.labora.tech";
// export const baseUrl = "http://127.0.0.1:3333";				//Para teste local:
export const baseUrl = "https://beta-adminapi3.labora.tech/";	//Servidor de testes.


export const urlUserLogin = `${baseUrl}/user/login`;

export const urlGetUser = `${baseUrl}/user`;
export const urlResetPassword = `${baseUrl}/user/resetPassword`;
export const urlUpdateTempPass = `${baseUrl}/user/updateTempPassword`;
export const urlUpdatePassword = `${baseUrl}/user/updatePassword`;
export const urlGetOportunidades = `${baseUrl}/oportunidades/?perPage=50&page=1&modal=true&show=true`;
export const urlGetJornadaLabora = `${baseUrl}/thinkific/enrollments`;
export const urlGetProcSeletivos = `${baseUrl}/pipefy/cards`;
export const urlGetEmpresas = `${baseUrl}/checkpoints/workplaces`;
// export const urlGetJornadasRealizadas = `${baseUrl}/jornadas?page=1&perPage=500&order=DESC&field=createdAt`;
export const urlCertificados = `${baseUrl}/certificados`;
export const urlGetJornadasRealizadas = `${baseUrl}/jornadas?page=1&perPage=100&order=DESC&field=createdAt&populateuser=true&populatecheckpoint=true`;
