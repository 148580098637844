import React, { useState } from "react";
import { Button, MenuItem, TextField, Checkbox, Box } from "@mui/material";
// import { useForm } from "react-hook-form";


export default function Adicionar({ onAdicionar }) {
    const [novoEvento, setNovoEvento] = useState({
        title: "",
        start: "",
        end: "",
    });
    // const [expanded, setExpanded] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNovoEvento({ ...novoEvento, [name]: value });
    };

    // const handleToppleExpended = (e) => {
    //     e.stopPropagation();
    //     setExpanded(!expanded);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (novoEvento.title && novoEvento.start && novoEvento.end) {
            const startDate = new Date(novoEvento.start);
            const endDate = new Date(novoEvento.end);
            if (startDate >= endDate) {
                alert("Hora de início deve ser inferior à hora de término !!!");
                return;
            };
            onAdicionar(novoEvento);
            setNovoEvento({
                title: "",
                start: "",
                end: "",
            });
        }
    };

    // const {
    //     register,
    //     formState: { errors },
    //   } = useForm({ criteriaMode: "all" });



    return (
        <div className="adicionar">
            <h3>Adicionar</h3>
            <h3>disponibilidade</h3>
            <form onSubmit={handleSubmit}>
                <div>
                    <p>Título</p>
                    {/* </div> */}
                    <TextField
                        className="agendar"
                        // label="Título"
                        type="text"
                        id="title"
                        name="title"
                        variant="outlined"
                        size="small"
                        placeholder="Digite um título"
                        value={novoEvento.title}
                        onChange={handleChange}
                    />
                    {/* <div> */}
                    <p>Início</p>
                    {/* </div> */}
                    <TextField
                        className="agendar"
                        // label="Início"
                        type="datetime-local"
                        id="start"
                        name="start"
                        variant="outlined"
                        size="small"
                        // placeholder="Digite o início"
                        value={novoEvento.start}
                        onChange={handleChange}
                    />
                    {/* <div> */}
                    <p>Término</p>
                    <TextField
                        className="agendar"
                        // label="Término"
                        type="datetime-local"
                        id="end"
                        name="end"
                        variant="outlined"
                        size="small"
                        // placeholder="Digite o término"
                        value={novoEvento.end}
                        onChange={handleChange}
                    />
                </div>
                <Button
                    className="btn-banner"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!novoEvento.title || !novoEvento.start || !novoEvento.end}
                >
                    Salvar
                </Button>
            </form>
        </div>
    )
}