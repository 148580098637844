import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, AuthContext } from "../contexts/auth";
import { UserProvider } from "../contexts/userUpdate";
import Home from "../pages/Home";
import Perfil from "../pages/Perfil.jsx";
import Oportunidades from "../pages/Oportunidades.jsx";
import Login from "../pages/Login";
import PasswordReset from "../pages/PasswordReset";
import Cadastro from "../pages/Cadastro";
import NoPage from "../pages/NoPage";
import UpdatePassword from "../pages/UpdatePassword";
import UpdateTempPass from "../pages/UpdateTempPass";
import JornadaLabora from "../pages/JornadaLabora";
import ProcSeletivos from "../pages/ProcSeletivos";
import JornadasRealizadas from "../pages/JornadasRealizadas";
import Disponibilidade from "../pages/Disponibilidade.jsx";
// import Empresas from "../pages/Empresas";
// import Certificados from "../pages/Certificados";

const Rotas = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);
    if (loading) {
      return <div className="loading"> Carregando... </div>;
    }
        
    if (!authenticated) {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  };
  return (
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <Routes>
            <Route exact path="/home" element={ 
              <Private>
                <Home />
              </Private>
              }
            />
            <Route path="/perfil" element={
              <Private>
                <Perfil />
              </Private>
              }
            />
            <Route
              path="/oportunidades" element={
                <Private>
                  <Oportunidades />
                </Private>
              }
            />
            <Route
              path="/updatePassword" element={
                <Private>
                  <UpdatePassword />
                </Private>
              }
            />
            <Route
              path="/updateTempPass" element={
                <Private>
                  <UpdateTempPass />
                </Private>
              }
            />
            <Route
              path="/disponibilidade" element={
                <Private>
                  <Disponibilidade />
                </Private>
              }
            />
            <Route
              path="/jornadaLabora" element={
                <Private>
                  <JornadaLabora />
                </Private>
              }
            />
            <Route
              path="/procSeletivos" element={
                <Private>
                  <ProcSeletivos />
                </Private>
              }
            />
            <Route
              path="/jornadasRealizadas" element={
                <Private>
                  <JornadasRealizadas />
                </Private>
              }
            />
            {/* <Route
              path="/empresas" element={
                <Private>
                  <Empresas />
                </Private>
              }
            /> */}
            {/* <Route
              path="/certificados" element={
                <Private>
                  <Certificados />
                </Private>
              }
            /> */}
            {" "}

            <Route path="*" element={<NoPage />} />{" "}
            <Route exact path="/" element={<Login />} />{" "}
            <Route path="/passwordReset" element={<PasswordReset />} />{" "}
            <Route path="/cadastro" element={<Cadastro />} />{" "}
            {/* <Route path="*" element={<NoPage />} />{" "} */}
          </Routes>{" "}
        </UserProvider>{" "}
      </AuthProvider>{" "}
    </BrowserRouter>
  );
};

export default Rotas;
