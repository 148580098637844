import React from "react";
import logolabora2 from "../../assets/images/laboralogo2.png";
import logolaborab from "../../assets/images/certified-b-corp-logo.png";
import "./Footer.css";


export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="logo-footer">
          {/* <img src="../../assets/images/laboralogo2.png" alt="logo labora tech" /> */}
          <img className="img-logo1" src={logolabora2} alt="logo labora tech" />
          <br />
          <img
            className="img-logo2"
            src={logolaborab}
            alt="logo labora certificacao b"
          />
        </div>
        <div className="container-footer">
          <div className="a-labora">
            <h5>A LABORA</h5>
            <a href="https://www.labora.tech/sobre-nos" className="link-footer">Sobre nós</a><br />
            <a href="https://www.labora.tech/nossa-causa" className="link-footer">Impacto social</a><br />
            <a href="mailto:contato@labora.tech" className="link-footer">Fale conosco</a><br />
            <a href="https://www.labora.tech/politica-de-privacidade" className="link-footer">Política de privacidade</a>
          </div>
          <div className="labora-products">
            <h5>NOSSO PRODUTO</h5>
            <a href="https://www.labora.tech/sou-empresa" className="link-footer">Para empresas</a><br />
            <a href="https://www.labora.tech/sou-talento" className="link-footer">Para seniores</a><br />
            {/* <a href="/" className="link-footer">Como funciona</a> */}
          </div>
          <div className="labora-newsletter">
            <h5>NEWSLETTER</h5>
            <p>Acompanhe as novidades e mantenha-se conectado.</p>
            <form>
              <div>
                <input className="radio" type="radio" name="talento" defaultValue="Empresa" />
                <label htmlFor="talento">Empresa</label>
              </div>
              <div>
                <input className="radio" type="radio" name="talento" defaultValue="Talento" />
                <label htmlFor="talento">Talento</label>
              </div>
              <input type="email" name="email" defaultValue="Digite seu e-mail" />
              <input className="botao-footer" type="submit"></input>
            </form>
          </div>
        </div>
      </footer>
    </>
  );
}
