import React, { useState, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { urlUserLogin } from "../utils/Utils.js";
import { api, createSession } from '../services/api';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

	const navigate = useNavigate();
	const [user, setUser] = useState('');
	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');
	// const [token, setToken] = useState('');

	useEffect(() => {
		const recoveredUser = localStorage.getItem('user');
		if(recoveredUser){
			setUser(JSON.parse(recoveredUser));
			//console.log("User: ", user);
		}
		setLoading(false);
		setErrorMsg('');
	}, []);


	const login = async (email, password, captcha) => {
		//console.log("login routes", email, password, captcha);

		const response = await createSession(email, password, captcha);
		//console.log("login response", response);

		if((response.msg === "User and/or Password incorrect.") || (response.msg === "email and/or password undefined.")){
			setErrorMsg(response.msg);
		};

		const loggedUser = response.data.user.email;
		//console.log('loggedUser:', loggedUser);

		const token = response.data.token;

		localStorage.setItem("user", JSON.stringify(loggedUser));
		localStorage.setItem("token", token);

		api.defaults.headers.Authorization = `Bearer ${token}`;

		setUser(loggedUser);
		//console.log('Is_temp_password:', response.data.user.is_temp_password);
		if(response.data.user.is_temp_password){ 
			//console.log("IsTempPass:", response.data.user.is_temp_password);
			navigate("/updateTempPass");
		}else{
			navigate("/home");
		}

	};

	const logout = () => {
		//console.log("logout");
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		api.defaults.headers.Authorization = null;
		setUser(null);
		navigate("/");
		setErrorMsg("");
	};

	const resetErrorMsg = () => {
		setErrorMsg("");
	}

	return (
		<AuthContext.Provider value={{ authenticated: !!user, user, loading, login, logout, errorMsg, resetErrorMsg }}>
			{children}
		</AuthContext.Provider>
	)
};