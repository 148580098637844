import axios from 'axios';
import { baseUrl, urlUserLogin, urlGetUser, urlGetOportunidades, urlUpdatePassword, urlUpdateTempPass, 
  urlGetJornadaLabora, urlGetProcSeletivos, urlGetEmpresas, urlCertificados, urlGetJornadasRealizadas } from '../utils/Utils';
export const api = axios.create({
  baseURL: baseUrl,
});

export const createSession = async (email, password, captcha) => {
  const response = await api.post(urlUserLogin, {
    email,
    password,
    captcha,
  }).catch(error => {
    console.error("error createSession: ", error);
    // console.error("error status: ", error.response.data.msg);
    return {
      status: "error",
      msg: error.response.data.msg
      };
  });
  // console.log("createSession response API:", response);
  return response;
};

export const readUser = async (token) => {
  // console.log("entrei readUser da api");
  // console.log("token do readUser-api.js:", token);

  const response = await api.get(urlGetUser, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  // console.log("readUser response API:", response);
  return response;
  // console.log("readUser userData-api:", userData);

};
export const updateUser = async (token, data) => {
  // console.log("entrei updateUser da api");
  // data.termo_de_uso = 'Aceito';
  data.append(`termo_de_uso`, 'Aceito');

  return await api.put(urlGetUser, data, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
  // console.log("updateUser userData-api:", userData);
}

export const readOportunidades = async (token) => {
  // console.log("entrei readOportunidades da api");
  // console.log("token do readOportunidades-api.js:", token);

  const response = await api.get(urlGetOportunidades, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  // console.log("readOportunidades response API:", response);
  return response;
  // console.log("readOportunidades userData-api:", userData);

};

export const updatePassword = async (token, data ) => {
  // console.log("Entrei updatePassword da api");
  return await api.put(urlUpdatePassword, data, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
  // console.log("updatePassword userData-api:", userData);
}

export const updateTempPassword = async (token, data ) => {
  // console.log("Entrei updateTempPass da api");
  return await api.put(urlUpdateTempPass, data, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
  // console.log("updatePassword userData-api:", userData);
}

export const readJornadaLabora = async (token) => {

  const response = await api.get(urlGetJornadaLabora, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readProcSeletivos = async (token) => {

  const response = await api.get(urlGetProcSeletivos, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readEmpresas = async (token) => {

  const response = await api.get(urlGetEmpresas, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readCertificados = async (token) => {

  const response = await api.get(urlCertificados, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readJornadasRealizadas = async (token) => {

  const response = await api.get(urlGetJornadasRealizadas, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};
