import React from 'react';
import "../ModalOportunidades/ModalOportunidades.css"
import "moment/locale/pt-br";

export default function MsgModal({ dataMsg }) {
    const moment = require("moment");
    const dia = moment(dataMsg.inscricao_at);
    const hoje = moment(dia).locale("pt-br").format("LL");
    let encerrada = true;
    let aberta = false;
    if (dataMsg.status) {
        aberta = true;
        encerrada = false;
    } else {
        aberta = false;
        encerrada = true;
    }

    return (
        <>
            {aberta &&
                <div>
                    <h4>Inscrições até {hoje}</h4>
                </div>}
            {encerrada &&
                <div>
                    <h4>Essa oportunidade está encerrada</h4>
                </div>}
        </>
    );
}