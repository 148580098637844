import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

export default function MenuPerfil() {

	let navigate = useNavigate();
	const routeToHome = () => navigate("/home");
	const routeToPerfil = () => navigate("/perfil");
	const routeToOportunidades = () => navigate("/oportunidades");
	const routeToUpdatePassword = () => navigate("/updatePassword");
	const routeTocertificacao = () => window.open("https://certificado-labora.thinkific.com/", "_blank");
	const routeToJornadaLabora = () => navigate("/jornadaLabora");
	const routeToProcSeletivos = () => navigate("/procSeletivos");
	const routeToJornadasRealizadas = () => navigate("/jornadasRealizadas");
	const routeToDisponibilidade = () => navigate("/disponibilidade");

	const { logout } = useContext(AuthContext);
	const handleLogout = () => {
		logout();
	};
	const [anchorE1, setAnchorE1] = useState(null);
	const open = Boolean(anchorE1);
	const handleClick = (event) => {
		setAnchorE1(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorE1(null);
	};

	return (
		<>
			<Box sx={{ display: "flex", "& > *": { mt: 2, ml: 4 } }}>
				<ButtonGroup
					orientation="vertical"
					aria-label="vertical contained button group"
					variant="contained"
					color="secondary"
					size="medium"
				>
					<Button color="secondary" onClick={routeToHome} key="Home">
						Home
					</Button>
					<Button color="secondary" onClick={routeToPerfil} key="Perfil">
						Perfil
					</Button>
					<Button
						color="secondary"
						onClick={routeTocertificacao}
						key="Certificacao"
					>
						Certificações Labora
					</Button>
					<Button
						color="secondary"
						onClick={routeToOportunidades}
						key="Oportunidades"
					>
						Oportunidades
					</Button>
					<Button
						color="secondary"
						onClick={routeToUpdatePassword}
						key="UpdatePaswword"
					>
						Alterar Senha
					</Button>
					<Button
						color="secondary"
						aria-controls={open ? "basic-menu" : undefined}
						aria-expanded={open ? "true" : undefined}
						aria-haspopup="true"
						onClick={handleClick}
					>
						Jornada Labora
					</Button>
					<Menu
						id="basic-menu"
						anchorE1={anchorE1}
						open={open}
						keepMounted
						onClose={handleClose}
						anchorReference="anchorPosition"
						anchorPosition={{ top: 300, left: 250 }}
						MenuListProps={{ "aria-labelledby": "basic-button" }}
					>
						<MenuItem onClick={routeToJornadaLabora}>Certificações</MenuItem>
						<MenuItem onClick={routeToDisponibilidade}>Disponibilidade</MenuItem>
						<MenuItem onClick={routeToJornadasRealizadas}>Jornadas Realizadas</MenuItem>
						<MenuItem onClick={routeToProcSeletivos}>Processos Seletivos</MenuItem>
						{/* <MenuItem onClick={routeToEmpresas}>Trabalho Flex</MenuItem> */}
						{/* <MenuItem onClick={routeToCertificados}>Certificados</MenuItem> */}
					</Menu>
					<Button color="secondary" onClick={handleLogout} key="Sair">
						Sair
					</Button>
				</ButtonGroup>
			</Box>
		</>
	);
}
