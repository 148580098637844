import React, { useEffect, useState } from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import SocialMedias from "../components/SocialMedias/SocialMedias.jsx";
import "./PagesStyles.css";
import { readProcSeletivos } from "../services/api";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import "moment/locale/pt-br";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Paragrafo = styled(Typography)(({ theme }) => ({
    fontSize: "1.4rem",
    color: "text.secondary",
    fontFamily: "Raleway",
    margin: "-1rem 0 0.5rem 0",
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.6rem",
        margin: "-0.5rem 0 0.1rem 0",
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "0.95rem",
        margin: "-0.5rem 0 0.25rem 0",
    },
    [theme.breakpoints.between("md", "lg")]: {
        fontSize: "1.1rem",
        margin: "-0.5rem 0 0.5rem 0",
    },
}));

export default function ProcSeletivos() {
    const token = localStorage.getItem("token");
    const [loaded, setLoaded] = useState(false);
    const moment = require("moment");
    const [matriz, setMatriz] = useState("");
    const [existeProcsel, setExisteProcsel] = useState(true);

    useEffect(() => {
        async function fetchProcSeletivos() {
            const procSeletivosResponse = await readProcSeletivos(token);
            console.log("Select: ", procSeletivosResponse.data.rows.length);
            setLoaded(true);
            if (procSeletivosResponse.data.rows.length !== 0) {
                console.log("ProcSeletivosResponse: ", procSeletivosResponse);
                const select = procSeletivosResponse.data.rows.map((selecao, index) => {
                    return {
                        id: index,
                        nome: selecao.pipefy_pipe.pipe_name,
                        // nome: selecao.pipefy_pipe.pipe_name.slice(16).split("- "),
                        inicio: moment(selecao.updated_at).locale("pt-br").format("LL"),
                        fim: moment(selecao.pipefy_pipe.last_updated).locale("pt-br").format("LL"),
                        tempo: new Date(selecao.pipefy_pipe.created_at).getTime(),
                    }
                });
                console.log("Select: ", select);
                let resultado = [...select].sort((a, b) => a.tempo > b.tempo ? 1 : -1);
                setMatriz(resultado);
            } else { setExisteProcsel(false); }
        }
        fetchProcSeletivos();
    }, [token, moment]);

    if (!loaded) {
        return <div>Carregando Processos Seletivos...</div>;
    } else {
        return (
            <>
                <div className="container-header">
                    <header className="app-header">
                        <Banner title="Processos Seletivos" description="Processos Seletivos Labora Tech." />
                    </header>
                </div>
                <div className="container-perfil">
                    <div className="container-menu">
                        <MenuPerfil></MenuPerfil>
                    </div>
                    <div className="box-jornada">
                        <div className="procsel-header">
                            {existeProcsel && (
                                <h1>Participação nos processos seletivos abaixo:</h1>)}
                            {!existeProcsel && (
                                <h1>Não participou de processos seletivos na Labora !</h1>)}
                        </div>
                        {existeProcsel && (
                            matriz.map((procSeletivo, index) => (
                                <div className="procsel-nome" key={index}>
                                    <Box sx={{ display: 'flexBox', ml: 1 }}>
                                        <Box sx={{ flexGrow: 1, mt: -1.5 }}>
                                            <Box sx={{ mt: -1.5 }}>
                                                <h4>{procSeletivo.nome}</h4>
                                            </Box>
                                            <Paragrafo>
                                                <strong>Início: </strong>{procSeletivo.inicio}
                                                {"  -  "}
                                                <strong>Término: </strong>{procSeletivo.fim}
                                            </Paragrafo>
                                        </Box>
                                    </Box>
                                </div>
                            ))
                        )}
                    </div>
                </div >
                <Footer></Footer>
                <SocialMedias></SocialMedias>
            </>
        );
    }
}
