const EventoPadrao = [
    {
        id: 1,
        title: "Atividade 1",
        start: new Date(2024, 4, 6, 8, 0),
        end: new Date(2024, 4, 6, 12, 0),
    },
    {
        id: 2,
        title: "Atividade 2",
        start: new Date(2024, 4, 7, 13, 0),
        end: new Date(2024, 4, 7, 17, 0),
    }
];

export default EventoPadrao;
