import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import ReCAPTCHA from "react-google-recaptcha";
import BannerTermoUso from "../components/BannerTermoUso/BannerTermoUso";
import { Box } from "@mui/system";
import ModalMsg from "../components/ModalMsg/ModalMsg.jsx";
import { TextField, Button } from "@mui/material";
import "./PagesStyles.css";

const Login = () => {
	let { login, errorMsg, resetErrorMsg } = useContext(AuthContext);

	const recaptchaRef = useRef();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errMsg, setErrMsg] = useState("");
	const navigate = useNavigate(true);
	const [reloaded, setReloaded] = useState(false);
	const routeToPasswordReset = () => navigate("/passwordReset");

	const routeToCadastro = () => {
		setErrMsg("");
		setReloaded(false);
		errorMsg = null;
		navigate("/cadastro");
		resetErrorMsg();
	};

	useEffect(() => {
		function fetchData() {
			if (errorMsg === "User and/or Password incorrect.") {
				setErrMsg("Usuário e/ou senha inválidos.");
				setReloaded(true);
			} else if (errorMsg === "email and/or password undefined.") {
				setErrMsg("Usuário e/ou senha incompletos.");
				setReloaded(true);
			} else {
				setErrMsg("");
				setReloaded(false);
			}
		}
		fetchData();
	}, [errMsg, errorMsg, reloaded]);

	async function handleFormSubmit(event) {
		event.preventDefault();
		await recaptchaRef.current.reset();
		const captcha = await recaptchaRef.current.executeAsync();
		login(email, password, captcha);
		setErrMsg("");
		resetErrorMsg();
	}

	const [loginData, resPassword] = useState({
		resetPass: false,
	});

	function goToRestorePass() {
		const data = { resetPass: true };
		resPassword(data);
	}

	if(loginData.resetPass) routeToPasswordReset();

	return (
		<>
			<div>
				<BannerTermoUso></BannerTermoUso>
			</div>
			<div className="container centro">
				<h1>"Bem vindo à Labora!"</h1>
				<h4>Entre com o seu e-mail e senha, ou cadastre-se para participar da Labora</h4>
				<form className="w75" onSubmit={handleFormSubmit}>
					<div className="centro">
						<Box
							className="box"
							sx={{ "& .MuiTextField-root": { m: 1, width: "95%" } }}
							noValidate
							autoComplete="off"
						>
							<TextField label="E-mail do usuário"
								type="email"
								id="email"
								name="email"
								variant="outlined"
								size="medium"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Box>
						<Box
							className="box"
							sx={{ "& .MuiTextField-root": { m: 1, width: "95%" } }}
							noValidate
							autoComplete="off"
						>
							<TextField label="Senha pessoal"
								type="password"
								id="password"
								name="password"
								variant="outlined"
								size="medium"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Box>
					</div>
					<div>
						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey="6LdzTBYgAAAAAN-toGlGYaNxFWTyxYwNVuXlXvOd"
							size="invisible"
							hl="pt-BR"
							badge="bottomleft"
						/>
					</div>
					<div className="pt10 botoes-login">
						<Button
							className="btn-login"
							variant="contained"
							color="secondary"
							type="submit"
							value="Logar"
							onClick={handleFormSubmit}
						>
							Entrar
						</Button>
						<Button
							className="btn-login"
							variant="contained"
							color="secondary"
							onClick={goToRestorePass}
						>
							Esqueci a senha
						</Button>
						<Button
							className="btn-login"
							variant="contained"
							color="secondary"
							onClick={routeToCadastro}
						>
							Cadastro
						</Button>
					</div>
					<Box>
						{errMsg === "Usuário e/ou senha inválidos." && reloaded && (
							<ModalMsg msg={errMsg} modalType={"success"} />
						)}
						{errMsg === "Usuário e/ou senha incompletos." && reloaded && (
							<ModalMsg msg={errMsg} modalType={"success"} />
						)}
					</Box>
				</form>
			</div>
		</>
	);
};

export default Login;
